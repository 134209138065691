import React from 'react'
import logoCredPicadaCafe from '../assets/imgs/logo-w.png'
import logosB from '../assets/imgs/logos.png'

const BcxSectionA = () => {
  return (
    <section id="SectionA">
      <div className="container">
        <div className="text">
          <p>
            O Cred Picada Café é uma solução para auxiliar prestadores de
            serviços e micro e pequenos empresários a transformarem os seus
            negócios.
          </p>
          <p>
            O objetivo é facilitar o acesso ao crédito, possibilitando
            investimentos em infraestrutura, planejamento de ampliação,
            melhorias de gestão e outras necessidades da empresa.
          </p>
          <p>
            Essa é uma união de esforços entre a Prefeitura de Picada Café,
            Sicredi Pioneira, RSGaranti e Sebrae para{' '}
            <strong>fortalecer a economia local!</strong>
          </p>
        </div>

        <div className="logos">
          <img
            className="credLogo"
            src={logoCredPicadaCafe}
            alt="Logotipo da CredPicadaCafe"
          />
          <img
            className="parceiros"
            src={logosB}
            alt="Logotipos Sicredi, Sebrae, RS Garanti, Prefeitura Municipal de Picada Café"
          />
        </div>
      </div>
    </section>
  )
}

export default BcxSectionA
