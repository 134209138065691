import React from 'react'
import Sicredi from '../assets/imgs/logo-1.jpg'
import Sebrae from '../assets/imgs/logo-2.jpg'
import RsGaranti from '../assets/imgs/logo-3.jpg'
import PrefeituraPicadaCafe from '../assets/imgs/logo-4.jpg'

const CcxFooter = () => {
  return (
    <section id="footer">
      <div className="container">
        <div className="logos">
          <a href="https://sicredipioneira.com.br" target="_blank">
            <img src={Sicredi} alt="Logo da Sicredi, Pioneira 120" />
          </a>
          <a href="https://sebraers.com.br" target="_blank">
            <img src={Sebrae} alt="Logo do Sebrae" />
          </a>
          <a href="https://www.rsgaranti.org.br/" target="_blank">
            <img src={RsGaranti} alt="Logo da RS Garanti" />
          </a>
          <a href="http://www.picadacafe.rs.gov.br" target="_blank">
            <img src={PrefeituraPicadaCafe} alt="Logo da RS Garanti" />
          </a>
        </div>
      </div>
    </section>
  )
}

export default CcxFooter
