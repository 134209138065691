const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

const UploadForm = ({ register, setValue, errors }) => {
  return (
    <div className="fileUpload">
      <h4>
        DASN SIMEI (Último ano – para MEI) | PGDAS-D (dos últimos 12 meses –
        para ME e EPP)
      </h4>
      {errors.files && <span className="erroForm">&gt; Obrigatório</span>}
      <div className="uploadAnexo">
        <input
          {...register('files', { required: true })}
          type="file"
          id="fileupload"
          name="file"
          className="campoStyle"
          accept="application/pdf"
          onChange={event => {
            const files = event.target.files || []
            if (files.length > 0) {
              toBase64(files[0]).then(b64 => {
                setValue('Base64DoArquivo', b64)
                setValue('NomeDoArquivo', files[0].name)
              })
            }
          }}
        />
      </div>
      <p>Anexe aqui seu documento (Tamanho Máximo: 2MB)</p>
    </div>
  )
}

export default UploadForm
