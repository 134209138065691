import React, { useEffect } from 'react'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import { maskCpf, maskData, maskPhoneNumber, maskCep } from '../utils/masks'

/*eslint-disable*/

const PfForm = ({ register, watch, errors, setValue }) => {
  //Campo Select Sexo
  const [sexo, setSexo] = React.useState('')
  const handleSexo = (event) => {
    setSexo(event.target.value)
  }

  const [sexoCj, setSexoCj] = React.useState('')
  const handleSexoCj = (event) => {
    setSexoCj(event.target.value)
  }

  //Estado Civil Conditional
  const estadoCivil = watch('EstadoCivil')

  //Somente Numeros no input
  const numeros = (evt) => {
    var theEvent = evt || window.event
    var key = theEvent.keyCode || theEvent.which
    key = String.fromCharCode(key)
    //var regex = /^[0-9.,]+$/;
    var regex = /^[0-9.,-/]+$/
    if (!regex.test(key)) {
      theEvent.returnValue = false
      if (theEvent.preventDefault) theEvent.preventDefault()
    }
  }

  //Mascaras de inputs Sócio
  const cpfValue = watch('Cpf')
  useEffect(() => {
    setValue('Cpf', maskCpf(cpfValue))
  }, [cpfValue])

  const phoneValue = watch('Telefone')
  useEffect(() => {
    setValue('Telefone', maskPhoneNumber(phoneValue))
  }, [phoneValue])

  const dataValue = watch('DataDeNascimento')
  useEffect(() => {
    setValue('DataDeNascimento', maskData(dataValue))
  }, [dataValue])

  const cepValue = watch('Cep')
  useEffect(() => {
    setValue('Cep', maskCep(cepValue))
  }, [cepValue])

  //Mascaras de inputs Conjuge
  const cpfConjugeValue = watch('CpfConjuge')
  useEffect(() => {
    setValue('CpfConjuge', maskCpf(cpfConjugeValue))
  }, [cpfConjugeValue])

  const phoneConjugeValue = watch('TelefoneConjuge')
  useEffect(() => {
    setValue('TelefoneConjuge', maskPhoneNumber(phoneConjugeValue))
  }, [phoneConjugeValue])

  const dataConjugeValue = watch('DataDeNascimentoConjuge')
  useEffect(() => {
    setValue('DataDeNascimentoConjuge', maskData(dataConjugeValue))
  }, [dataConjugeValue])

  const cepConjugeValue = watch('CepConjuge')
  useEffect(() => {
    setValue('CepConjuge', maskCep(cepConjugeValue))
  }, [cepConjugeValue])

  //validação de preenchimento
  const obrigatorio = { required: true }

  return (
    <div className="pfForm">
      <div className="socioTitle">
        <h2>Sócio Representante / Pessoa Física</h2>
      </div>

      <div className="grid">
        <div className="cpfSocio1">
          <TextField
            {...register('Cpf', obrigatorio)}
            label="CPF do Sócio"
            autoComplete="none"
            variant="filled"
            className="campoStyle"
          />
          {errors.Cpf && <span className="erroForm">&gt; Obrigatório</span>}
        </div>

        <div className="nomeSocio1">
          <TextField
            {...register('Nome', obrigatorio)}
            label="Nome do Sócio "
            autoComplete="none"
            variant="filled"
            className="campoStyle"
          />
          {errors.Nome && <span className="erroForm">&gt; Obrigatório</span>}
        </div>

        <div className="emailSocio1">
          <TextField
            {...register('Email', obrigatorio)}
            label="E-mail do Sócio"
            autoComplete="none"
            type="email"
            variant="filled"
            className="campoStyle"
          />
          {errors.Email && <span className="erroForm">&gt; Obrigatório</span>}
        </div>

        <div className="telefoneSocio1">
          <TextField
            {...register('Telefone', obrigatorio)}
            label="Telefone do Sócio"
            autoComplete="none"
            variant="filled"
            className="campoStyle"
          />
          {errors.Telefone && (
            <span className="erroForm">&gt; Obrigatório</span>
          )}
        </div>

        <div className="nascimentoSocio1">
          <TextField
            {...register('DataDeNascimento', obrigatorio)}
            label="Data de Nascimento"
            autoComplete="none"
            variant="filled"
            className="campoStyle"
          />
          {errors.DataDeNascimento && (
            <span className="erroForm">&gt; Obrigatório</span>
          )}
        </div>

        <div className="nomemaeSocio1">
          <TextField
            {...register('NomeDaMae', obrigatorio)}
            label="Nome da Mãe"
            autoComplete="none"
            variant="filled"
            className="campoStyle"
          />
          {errors.NomeDaMae && (
            <span className="erroForm">&gt; Obrigatório</span>
          )}
        </div>

        <div className="rgSocio1">
          <TextField
            {...register('Rg', obrigatorio)}
            label="RG"
            autoComplete="none"
            variant="filled"
            className="campoStyle"
            onKeyPress={numeros}
            inputProps={{
              maxLength: 15,
            }}
          />
          {errors.Rg && <span className="erroForm">&gt; Obrigatório</span>}
        </div>

        <Box sx={{ maxWidth: 540 }} className="sexoSocio1">
          <FormControl variant="filled" fullWidth className="campoStyle">
            <InputLabel>Sexo</InputLabel>
            <Select
              {...register('Sexo', obrigatorio)}
              value={sexo}
              label="Sexo"
              onChange={handleSexo}
            >
              <MenuItem value="0">Masculino</MenuItem>
              <MenuItem value="1">Feminino</MenuItem>
              <MenuItem value="2">Outro</MenuItem>
            </Select>
          </FormControl>
          {errors.Sexo && <span className="erroForm">&gt; Obrigatório</span>}
        </Box>

        <div className="naturalidadeSocio1">
          <TextField
            {...register('Naturalidade', obrigatorio)}
            label="Naturalidade"
            autoComplete="none"
            variant="filled"
            className="campoStyle"
          />
          {errors.Naturalidade && (
            <span className="erroForm">&gt; Obrigatório</span>
          )}
        </div>

        <div className="cepSocio1">
          <TextField
            {...register('Cep', obrigatorio)}
            label="CEP"
            autoComplete="none"
            variant="filled"
            className="campoStyle"
          />
          {errors.Cep && <span className="erroForm">&gt; Obrigatório</span>}
        </div>

        <div className="enderecoSocio1">
          <TextField
            {...register('Endereco', obrigatorio)}
            label="Endereço"
            autoComplete="none"
            variant="filled"
            className="campoStyle"
          />
          {errors.Endereco && (
            <span className="erroForm">&gt; Obrigatório</span>
          )}
        </div>

        <div className="bairroSocio1">
          <TextField
            {...register('Bairro', obrigatorio)}
            label="Bairro"
            autoComplete="none"
            variant="filled"
            className="campoStyle"
          />
          {errors.Bairro && <span className="erroForm">&gt; Obrigatório</span>}
        </div>

        <div className="estadoSocio1">
          <TextField
            {...register('Estado', obrigatorio)}
            label="Estado"
            autoComplete="none"
            variant="filled"
            className="campoStyle"
          />
          {errors.Estado && <span className="erroForm">&gt; Obrigatório</span>}
        </div>

        <div className="cidadeSocio1">
          <TextField
            {...register('Cidade', obrigatorio)}
            label="Cidade"
            autoComplete="none"
            variant="filled"
            className="campoStyle"
          />
          {errors.Cidade && <span className="erroForm">&gt; Obrigatório</span>}
        </div>

        <div className="sociedadeSocio1">
          <TextField
            {...register('Participacao', obrigatorio)}
            label="% Sociedade"
            autoComplete="none"
            variant="filled"
            className="campoStyle"
            onKeyPress={numeros}
            inputProps={{
              maxLength: 3,
            }}
          />
          {errors.Participacao && (
            <span className="erroForm">&gt; Obrigatório</span>
          )}
        </div>

        <FormControl className="estadoCivilSocio1 radioStyle">
          <FormLabel>Estado Civil</FormLabel>
          <RadioGroup className="estadoCivilRadio" row defaultValue="1">
            <FormControlLabel
              {...register('EstadoCivil', { required: true })}
              value="1"
              control={<Radio />}
              label="Solteiro(a)"
            />
            <FormControlLabel
              {...register('EstadoCivil', { required: true })}
              value="0"
              control={<Radio />}
              label="Casado(a)"
            />
            <FormControlLabel
              {...register('EstadoCivil', { required: true })}
              value="5"
              control={<Radio />}
              label="União Estável"
            />
            <FormControlLabel
              {...register('EstadoCivil', { required: true })}
              value="3"
              control={<Radio />}
              label="Divorciado(a)"
            />
            <FormControlLabel
              {...register('EstadoCivil', { required: true })}
              value="2"
              control={<Radio />}
              label="Viúvo(a)"
            />
          </RadioGroup>
        </FormControl>

        {/************Campos Conjuge**************/}
        <div className="conjugeFields">
          {(estadoCivil == 0 || estadoCivil == 5) && (
            <div className="conjugeTitle">
              <h2>Cônjuge Representante / Pessoa Física</h2>

              <div className="grid">
                <div className="cpfSocio1">
                  <TextField
                    {...register('CpfConjuge', obrigatorio)}
                    autoComplete="none"
                    label="CPF Cônjuge"
                    variant="filled"
                    className="campoStyle"
                  />
                  {errors.CpfConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </div>

                <div className="nomeSocio1">
                  <TextField
                    {...register('NomeConjuge', obrigatorio)}
                    autoComplete="none"
                    label="Nome Cônjuge"
                    variant="filled"
                    className="campoStyle"
                  />
                  {errors.NomeConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </div>

                <div className="emailSocio1">
                  <TextField
                    {...register('EmailConjuge', obrigatorio)}
                    autoComplete="none"
                    label="E-mail Cônjuge"
                    type="email"
                    variant="filled"
                    className="campoStyle"
                  />
                  {errors.EmailConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </div>

                <div className="telefoneSocio1">
                  <TextField
                    {...register('TelefoneConjuge', obrigatorio)}
                    autoComplete="none"
                    label="Telefone Cônjuge"
                    variant="filled"
                    className="campoStyle"
                  />
                  {errors.TelefoneConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </div>

                <div className="nascimentoSocio1">
                  <TextField
                    {...register('DataDeNascimentoConjuge', obrigatorio)}
                    autoComplete="none"
                    label="Nascimento Cônjuge"
                    variant="filled"
                    className="campoStyle"
                  />
                  {errors.DataDeNascimentoConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </div>

                <div className="nomemaeSocio1">
                  <TextField
                    {...register('NomeDaMaeConjuge', obrigatorio)}
                    autoComplete="none"
                    label="Nome da Mãe Cônjuge"
                    variant="filled"
                    className="campoStyle"
                  />
                  {errors.NomeDaMaeConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </div>

                <div className="rgSocio1">
                  <TextField
                    {...register('RgConjuge', obrigatorio)}
                    autoComplete="none"
                    label="RG Cônjuge"
                    variant="filled"
                    className="campoStyle"
                  />
                  {errors.RgConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </div>

                <Box sx={{ maxWidth: 540 }} className="sexoSocio1">
                  <FormControl
                    variant="filled"
                    fullWidth
                    className="campoStyle"
                  >
                    <InputLabel>Sexo Cônjuge</InputLabel>
                    <Select
                      {...register('SexoConjuge', obrigatorio)}
                      value={sexoCj}
                      label="Sexo"
                      onChange={handleSexoCj}
                    >
                      <MenuItem value="0">Masc</MenuItem>
                      <MenuItem value="1">Fem</MenuItem>
                      <MenuItem value="2">Outro</MenuItem>
                    </Select>
                  </FormControl>
                  {errors.SexoConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </Box>

                <div className="naturalidadeSocio1">
                  <TextField
                    {...register('NaturalidadeConjuge', obrigatorio)}
                    autoComplete="none"
                    label="Naturalidade Cônjuge"
                    variant="filled"
                    className="campoStyle"
                  />
                  {errors.NaturalidadeConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </div>

                <div className="cepSocio1">
                  <TextField
                    {...register('CepConjuge', obrigatorio)}
                    autoComplete="none"
                    label="CEP Cônjuge"
                    variant="filled"
                    className="campoStyle"
                  />
                  {errors.CepConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </div>

                <div className="enderecoSocio1">
                  <TextField
                    {...register('EnderecoConjuge', obrigatorio)}
                    autoComplete="none"
                    label="Endereço Cônjuge"
                    variant="filled"
                    className="campoStyle"
                  />
                  {errors.EnderecoConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </div>

                <div className="bairroSocio1">
                  <TextField
                    {...register('BairroConjuge', obrigatorio)}
                    autoComplete="none"
                    label="Bairro Cônjuge"
                    variant="filled"
                    className="campoStyle"
                  />
                  {errors.BairroConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </div>

                <div className="estadoSocio1">
                  <TextField
                    {...register('EstadoConjuge', obrigatorio)}
                    autoComplete="none"
                    label="Estado Cônjuge"
                    variant="filled"
                    className="campoStyle"
                  />
                  {errors.EstadoConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </div>

                <div className="cidadeSocio2">
                  <TextField
                    {...register('CidadeConjuge', obrigatorio)}
                    autoComplete="none"
                    label="Cidade Cônjuge"
                    variant="filled"
                    className="campoStyle"
                  />
                  {errors.CidadeConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </div>

                <div className="complementoSocio1">
                  <TextField
                    {...register('ComplementoConjuge', obrigatorio)}
                    autoComplete="none"
                    label="Complemento Cônjuge"
                    variant="filled"
                    className="campoStyle"
                  />
                  {errors.ComplementoConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </div>

                <div className="profissaoCj">
                  <TextField
                    {...register('ProfissaoConjuge', obrigatorio)}
                    autoComplete="none"
                    label="Profissão Cônjuge"
                    variant="filled"
                    className="campoStyle"
                  />
                  {errors.ProfissaoConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </div>

                <div className="rendaCj">
                  <TextField
                    {...register('RendaConjuge', obrigatorio)}
                    label="Renda Cônjuge"
                    autoComplete="none"
                    variant="filled"
                    className="campoStyle"
                    onKeyPress={numeros}
                  />
                  {errors.RendaConjuge && (
                    <span className="erroForm">&gt; Obrigatório</span>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default PfForm
