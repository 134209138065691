import React from 'react'
import Button from '@mui/material/Button';

function PoliticaDePrivacidade(props) {
    return (props.trigger) ? (
        <div className='popup'>
            <div className="popup-inner">
                <Button className="close-btn" onClick={() => props.setTrigger(false)}
                    variant="contained"
                    color="success">
                    Fechar
                </Button>

                {props.children}

            </div>
        </div>
    ) : "";
}

export default PoliticaDePrivacidade